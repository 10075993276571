import React, { useEffect } from 'react';
import we from '../../assets/img/imagenNosotro.jpeg';
import carmen from '../../assets/img/carmen-perfil.jpeg';
import mariano from '../../assets/img/mariano-perfil.jpeg';


export const We = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <main className="main">
            <section className="nosotros section">
                <h2 className="section_titulo textCenter" data-aos="zoom-in" data-aos-duration="1000">Sobre el estudio</h2>
                <div className="gridNosotros">
                    <div className="wrapper_left_nosotros">
                        <h4 className="wrapper_left_nosotros_title" data-aos="zoom-in" data-aos-duration="1000">El estudio</h4>
                        <p className="wrapper_left_nosotros_text" data-aos="zoom-in" data-aos-duration="1500">
                            En el año 2010 fundamos G+M Arquitectos Entendemos que cada obra
                            tiene necesidades específicas que la hacen única.
                        </p>
                        <br />
                        <p className="wrapper_left_nosotros_text" data-aos="zoom-in" data-aos-duration="1500">
                            Nos caracterizamos por brindar atención personalizada, llevando a
                            cabo los proyectos con dedicación y compromiso, cumpliendo con las
                            expectativas del cliente
                        </p>
                    </div>
                    <div className="wrapper_right_nosotros" data-aos="zoom-in" data-aos-duration="1500">
                        <img src={ we } alt="Imagen de una oficina" />
                    </div>
                </div>
            </section>
            <section className="nosotros section">
                <h2 className="section_titulo textCenter" data-aos="zoom-in" data-aos-duration="1000">Quiénes somos</h2>
                <div className="cardsText gridNosotros">
                    <div className="cardsText_card cardNosotros" data-aos="zoom-in" data-aos-duration="1500">
                        <div className="img texCenter marginTop tranY">
                            <img src={ carmen } alt="Carmen González" />
                        </div>
                        <h4 className="textCenter">Carmen González</h4>
                        <p><i className="far fa-check-circle" />Arquitecta UBA-2010</p>
                        <p>
                            <i className="far fa-check-circle" />Mejor promedio promoción 2010
                        </p>
                        <p>
                            <i className="far fa-check-circle" />Amplia experiencia en reformas,
                            diseño interior y viviendas
                        </p>
                        <p>
                            <i className="far fa-check-circle" />Especialización en patologías
                            constructivas y arquitectura educativa
                        </p>
                        <p>
                            <i className="far fa-check-circle" />Me caracterizo por: ser muy
                            detallista, tener paciencia y dedicación.
                        </p>
                    </div>
                    <div className="cardsText_card cardNosotros" data-aos="zoom-in" data-aos-duration="1500">
                        <div className="img texCenter tranY">
                            <img src={ mariano } alt="Mariano Matarazzo" />
                        </div>
                        <h4 className="textCenter">Mariano Matarazzo</h4>
                        <p><i className="far fa-check-circle" />Arquitecto UBA-2010</p>
                        <p>
                            <i className="far fa-check-circle" />Amplia experiencia en
                            viviendas, estructuras y patologías constructivas
                        </p>
                        <p>
                            <i className="far fa-check-circle" />Posgrado en diseño de
                            mobiliario
                        </p>
                        <p>
                            <i className="far fa-check-circle" />Me caracterizo por: puntualidad
                            con tiempos de entrega y seguimiento constante de las etapas de la
                            obra
                        </p>
                    </div>
                </div>
            </section>
        </main>
    )
}