import React, { useContext } from 'react';
import { useParams } from 'react-router';
import { AppContext } from '../../../context/AppContext';
import { Detail } from '../../pages/Detail';

export const DetailReformsContainer = () => {

    let { id } = useParams();

    const { reformas:{reformasData,reformasLoading,reformasError} } = useContext(AppContext);
    
    return (
        <>
            {   
                reformasLoading ?
                (
                    <h1>Loading...</h1>
                )   :
                reformasError ?
                (
                    <h1>{ reformasError }</h1>
                )   :
                (
                    reformasData && 
                    (
                        reformasData.map( (d) => (
                            id === d.id && (
                                <Detail key={d.id} props={{...d}} />
                            )    
                        ))
                    )
                )
            }
        </>
    )
}
