import React, { useLayoutEffect, useRef } from 'react';
import {
    BrowserRouter as Router,Switch,Route, Redirect
} from "react-router-dom";
import './styles/styles.scss';
import { AppProvider } from './context/AppContext';
import { Navbar } from './components/ui/Navbar';
import { Index } from './components/pages/Index';
import { OnSale } from './components/pages/OnSale';
import { NewSale } from './components/pages/NewSale';
import { InReform } from './components/pages/InReform';
import { Services } from './components/pages/Services';
import { We } from './components/pages/We';
import { Contact } from './components/pages/Contact';
import { Footer } from './components/ui/Footer';
import { FullDetailContainer } from './components/details/fullDetail/FullDetailContainer';
import { DetailContainer } from './components/details/detail/DetailContainer';
import { DetailReformsContainer } from './components/details/detailReforms/DetailReformsContainer';


import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {

    useLayoutEffect(() => {
        AOS.init();
        return () => {
            AOS.init();
        };
    },[])

    const main = useRef();
    return (
        <AppProvider>
            <Router>
                <header>
                    <Navbar main={main}/>
                </header>
                <main ref={main}>
                    <Switch>
                        <Route exact to path="/" component={Index} />

                        <Route exact to path="/ventas" component={OnSale} />
                        <Route exact to path="/nuevas-ventas" component={NewSale} />
                        <Route exact to path="/reformas" component={InReform} />

                        <Route exact to path="/ventas/:id" component={FullDetailContainer} />
                        <Route exact to path="/nuevas/:id" component={DetailContainer} />
                        <Route exact to path="/reformas/:id" component={DetailReformsContainer} />

                        <Route exact to path="/servicios" component={Services} />
                        <Route exact to path="/nosotros" component={We} />
                        <Route exact to path="/contacto" component={Contact} />

                        <Redirect to="/" />
                    </Switch>
                </main>
                <>
                    <Footer />
                </>
            </Router>
        </AppProvider>
    )
}

export default App;
