import React, { useContext } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import SwiperCore, {Pagination,Navigation,Autoplay} from 'swiper/core';
import { AppContext } from '../../context/AppContext';
SwiperCore.use([Pagination,Navigation,Autoplay]);

export const Slider = () => {
    
    const { slider:{sliderData,sliderLoading,sliderError} } = useContext(AppContext);

    return (
        <Swiper spaceBetween={30} centeredSlides={true} autoplay={{ "delay": 2500, "disableOnInteraction": false }}
            pagination={{ "clickable": true }} navigation={true} className="mySwiper">
                {
                    sliderLoading ?
                    (
                        <h1>Loading...</h1>
                    )   :
                    sliderError ?
                    (
                        <h1>{ sliderError }</h1>
                    )   :
                    (
                        sliderData && 
                        (
                            sliderData.map( ( d, i ) => (
                                (   
                                    <SwiperSlide key={ i }><img src={d.imagen} alt="Arquitectos G+M" /></SwiperSlide>
                                )  
                            ))
                        )
                    )
                }
        </Swiper>
    )
}
