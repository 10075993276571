import React, { useEffect } from 'react';
import { Slider } from '../ui/Slider';
import { TestimonialsSlider } from '../ui/TestimonialsSlider';
import des1 from '../../assets/destacadas/des1.jpg';
import des2 from '../../assets/destacadas/des2.jpg';
import des3 from '../../assets/destacadas/des3.jpg';
// import des4 from '../../assets/destacadas/des4.jpg';
// import des5 from '../../assets/destacadas/des5.jpg';
// import des6 from '../../assets/destacadas/des6.jpg';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Testimonials } from '../ui/Testimonials';
import { Link } from 'react-router-dom';

export const Index = () => {

    const { width } = useWindowDimensions();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <main className="main">
            <section className="home">
                <Slider />
                <div className="homeTextos" data-aos="zoom-in" data-aos-duration="1000">
                    <h1>Te ayudamos a concretar tus proyectos</h1>
                    <p>
                        Diseños originales pensados según las necesidades de cada<br />
                        cliente. Brindamos asesoramiento y seguimiento personalizado.
                    </p>
                    <a className="button" href="https://api.whatsapp.com/send?phone=541166402983&amp;text=Hola!&nbsp;Quiero&nbsp;solicitar&nbsp;una&nbsp;cotización">Solicitar cotización</a>
                </div>
            </section>
            {/* Elegirnos */}
            <section className="elegirnos section">
                <h2 className="section_titulo textCenter" data-aos="zoom-in" data-aos-duration="1000">¿Por qué elegirnos?</h2>
                <div className="cardsText grid">
                    <div className="cardsText_card" data-aos="zoom-in" data-aos-duration="1500">
                        <h4 className="textCenter">Experiencia</h4>
                        <p className="textCenter">Amplia trayectoria en proyecto y dirección de obras</p>
                    </div>
                    <div className="cardsText_card" data-aos="zoom-in" data-aos-duration="1500">
                        <h4 className="textCenter">Compromiso</h4>
                        <p className="textCenter">
                            La obra es entregada en tiempo y forma, asesorando y acompañando a
                            nuestros clientes en forma personalizada.
                        </p>
                    </div>
                    <div className="cardsText_card" data-aos="zoom-in" data-aos-duration="1500">
                        <h4 className="textCenter">Calidad</h4>
                        <p className="textCenter">
                            Cumplimos rigurosas normas de calidad y hacemos dirección diaria
                            para poder supervisar cada detalle. Estamos en constante
                            actualización para poder ofrecer el mejor asesoramiento
                        </p>
                    </div>
                </div>
            </section>
            {/* Propiedades destacadas */}
            <section className="propiedadesDestacadas section">
                <h2 className="section_titulo textCenter" data-aos="zoom-in" data-aos-duration="1000">Nuestras obras</h2>
                <div className="cardsText grid">
                    <div className="cardsPropiedades" data-aos="zoom-in" data-aos-duration="1500">
                        <div className="imagenGaleriaProdSH tranY">
                            <Link to="ventas">    
                                <img src={ des1 } alt="CASA 3B" />
                                <div className="hoverGaleriaSH">
                                    <p>EN VENTA</p>
                                    <span>VER MÁS >></span>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="cardsPropiedades" data-aos="zoom-in" data-aos-duration="1500">
                        <div className="imagenGaleriaProdSH tranY">
                            <Link to="nuevas-ventas">    
                                <img src={ des2 } alt="CASA JS" />
                                <div className="hoverGaleriaSH">
                                    <p>NUEVAS</p>
                                    <span>VER MÁS >></span>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="cardsPropiedades" data-aos="zoom-in" data-aos-duration="1500">
                        <div className="imagenGaleriaProdSH tranY">
                            <Link to="reformas">    
                                <img src={ des3 } alt="QUINCHO Y TERRAZA CHIVILCOY" />
                                <div className="hoverGaleriaSH">
                                    <p>REFORMAS</p>
                                    <span>VER MÁS >></span>
                                </div>
                            </Link>
                        </div>
                    </div>
                    {/* <div className="cardsPropiedades" data-aos="zoom-in" data-aos-duration="1500">
                        <div className="imagenGaleriaProd tranY">
                            <Link to="reformas/6">    
                                <img src={ des4 } alt="CASA MARMOL" />
                                <div className="hoverGaleria">
                                    <p>CASA MARMOL</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="cardsPropiedades" data-aos="zoom-in" data-aos-duration="1500">
                        <div className="imagenGaleriaProd tranY">
                            <Link to="nuevas/1">    
                                <img src={ des5 } alt="CASA NC" />
                                <div className="hoverGaleria">
                                    <p>CASA NC</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="cardsPropiedades" data-aos="zoom-in" data-aos-duration="1500">
                        <div className="imagenGaleriaProd tranY">
                            <Link to="nuevas/4">    
                                <img src={ des6 } alt="CASA SB" />
                                <div className="hoverGaleria">
                                    <p>CASA SB</p>
                                </div>
                            </Link>
                        </div>
                    </div> */}
                </div>
            </section>
            {/* Testimonios */}
            <section className="testimonios section">
                <h2 className="section_titulo textCenter" data-aos="zoom-in" data-aos-duration="1000">Testimonios</h2>
                { width <= 800 ? 
                    (
                        <Testimonials />
                    )   :
                    (
                        <TestimonialsSlider />
                    )
                }
                {/* <TestimonialsSlider /> */}

            </section>
            {/* Maps */}
            <section className="mapa" data-aos="zoom-in" data-aos-duration="1500">
                <iframe
                    title="mapa" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3284.1780953277776!2d-58.439082184770506!3d-34.59965778046051!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcca73a20c7eb9%3A0x42505c9fa24435ed!2sAr%C3%A1oz%20406%2C%20C1414DPJ%20CABA!5e0!3m2!1ses-419!2sar!4v1636086299913!5m2!1ses-419!2sar"
                    width="100%" height={500} style={{border: 0}} allowFullScreen loading="lazy" />
            </section>
        </main>
    )
}