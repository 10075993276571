import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { useForm } from '../../hooks/useForm';

export const Footer = () => {

    let copyYear = new Date().getFullYear();

    const sender = useRef();

    const [ formValues, handleInputChange, reset ] = useForm({
        NewsLetterEmail: ''
    });
    const { NewsLetterEmail } = formValues;

    const [ err, setErr ] = useState({
        errMail: ''
    })
    const { errMail } = err;

    const handleError = () => {
        let error = {};
        if ( !NewsLetterEmail ) {
            error.errMail = 'El Email es requerido';
        } else if ( !/\S+@\S+\.\S+/.test(NewsLetterEmail) ) {
            error.errMail = 'La direccion de Email es invalida';
        }
        return setErr(error)
    }

    const [loadingAndErr, setLoadingAndErr] = useState({
        loading: false,
        error: false,
        ok: false
    })
    const { loading, error, ok } = loadingAndErr;
    
    const handleForm = (e) => {
        e.preventDefault();

        if (!errMail) {
            setLoadingAndErr({ ...loadingAndErr,loading: true });

            sender.current.disabled = true;
            sender.current.classList.add('disabled--btn');

            fetch("https://formsubmit.co/ajax/cd910b5d5fd7a52d887e5270815f73dc", {
                method: "POST",
                headers: { 
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({...formValues})
            })
            .then(response => response.json())
            .then(data => {
                if (data.success === 'true') {
                    setLoadingAndErr({ ...loadingAndErr,loading: false, ok: 'El mensaje fue enviado con exito' });
                    reset();
                } else {
                    throw new Error(data.message);                
                }
                console.log(data);
            })
            .catch(error => {
                setLoadingAndErr({ ...loadingAndErr,loading: false, error: 'Ocurrio un error, intente nuevamente!' });
                console.warn(error);
            })
            .finally(
                setTimeout(() => {
                    setLoadingAndErr({
                        loading: false,
                        error: false,
                        ok: false
                    });
                    sender.current.disabled = false;
                    sender.current.classList.remove('disabled--btn');
                }, 5000)
            )
        };
    };
    

    return (
        <footer className="footer section">
            <div className="footer_first" data-aos="zoom-in" data-aos-duration="1500">
                <div className="newsletter row2">
                    <div className="text">
                        <h4 className="text_title">newsletter</h4>
                        <p className="text_para">
                            Suscribite para recibir novedades y promociones exclusivas.
                        </p>
                    </div>
                    <div className="newsletter__container">
                        <form className="search row2" onSubmit={ handleForm }>
                            <input type="email" name="NewsLetterEmail" className={ errMail ? "form-control is-invalid" : errMail === '' ? "" : "form-control is-valid" }
                                value={ NewsLetterEmail } onChange={ handleInputChange } placeholder="Ingresá tu email" />
                            {
                                loading ?
                                (
                                    <button className="email" type="submit" ref={ sender } onClick={ handleError }>
                                        <svg viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <text x="50%" y="50%" textAnchor="middle" fill="white" fontFamily="raleway" dy=".3em">
                                                Enviando...
                                            </text>
                                        </svg>
                                    </button>
                                )
                                :
                                (
                                    <button className="email" type="submit" ref={ sender } onClick={ handleError }>
                                        <svg viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <text x="50%" y="50%" textAnchor="middle" fill="white" fontFamily="raleway" dy=".3em">
                                                Suscribirse
                                            </text>
                                        </svg>
                                    </button>
                                )
                            }
                        </form>
                        {
                            errMail &&
                            (
                                <p className="inputErr">{ errMail }</p>
                            )
                        }
                        {
                            error &&
                            (
                                <p className="inputErr">{ error }</p>
                            )
                        }
                        {
                            ok &&
                            (
                                <p className="inputOk">{ ok }</p>
                            )
                        }
                    </div>
                </div>
            </div>
            <div className="footer_second">
                <div className="footer_list row2">
                    <div className="footer_list_row_1">
                        <div>
                            <h2 className="footer_item_title">Nosotros</h2>
                            <p className="footer_item_text">
                            Nos caracterizamos por brindar atención personalizada, llevando a cabo los proyectos con dedicación y compromiso, cumpliendo con las expectativas del cliente.
                            </p>
                        </div>
                        <div>
                            <h2 className="footer_item_title">Nuestras redes</h2>
                            <a href="https://www.facebook.com/gm.arquitectos/" rel="noreferrer" target="_blank"><i className="fab fa-facebook fa-2x tranYlow" /></a>
                            <a href="https://www.instagram.com/arquitectos_gm/" rel="noreferrer" target="_blank"><i className="fab fa-instagram fa-2x tranYlow" /></a>
                        </div>
                    </div>
                    <div className="footer_list_row">
                        <div>
                            <h2 className="footer_item_title">Links</h2>
                            <ul className="footer_link">
                                <li className="footer_links">
                                    <Link to="/">Inicio</Link>
                                </li>
                                <li className="footer_links">
                                    <Link to="/servicios">Servicios</Link>
                                </li>
                                <li className="footer_links">
                                    <Link to="/nosotros">Nosotros</Link>
                                </li>
                                <li className="footer_links">
                                    <Link to="/contacto">Contacto</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="footer_list_row">
                        <div className="footer_list_row_contacto">
                            <h2 className="footer_item_title">Contacto</h2>
                            <div className="footer_link">
                                <p className="tranYlow"><i className="far fa-envelope" /><a href="mailto:info@arquitectosgm.com.ar">info@arquitectosgm.com.ar</a></p>
                                <p className="tranYlow"><i className="fas fa-phone-volume" /><a href="https://api.whatsapp.com/send?phone=541166402983&amp;text=Hola!&nbsp;mi&nbsp;nombre&nbsp;es&nbsp;...">+54 9 11 6640-2983</a></p>
                                <p className="tranYlow"><i className="fas fa-map-marker-alt" />Araoz 406. CABA</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer__copyRightText">
			    <p>Copyright © 2021 { copyYear > 2021 && '- ' +copyYear} <a href="https://www.upwaydigitalsolutions.com"><u>Upway Digital Solutions</u></a>. Todos los derechos Reservados.</p>
		    </div>
        </footer>
    )
}