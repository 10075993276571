import React, { useContext } from 'react';
import { useParams } from 'react-router';
import { AppContext } from '../../../context/AppContext';
import { FullDetail } from '../../pages/FullDetail';

export const FullDetailContainer = () => {

    let { id } = useParams();

    const { ventas:{ventasData,ventasLoading,ventasError} } = useContext(AppContext);

    return (
        <>
            {   
                ventasLoading ?
                (
                    <h1>Loading...</h1>
                )   :
                ventasError ?
                (
                    <h1>{ ventasError }</h1>
                )   :
                (
                    ventasData && 
                    (
                        ventasData.map( (d) => (
                            id === d.id && (
                                <FullDetail key={d.id} props={{...d}} />
                            )    
                        ))
                    )
                )
            }
        </>
    )
}
