import { useState, useEffect } from 'react';

export const useFetch = (url = '', options = null) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let isMounted = true;

        fetch(url, options)
            .then(res => res.json())
            .then(data => {
                if (isMounted) {
                    setData(data);
                }
            })
            .catch(error => {
                if (isMounted) {
                    setError(error);
                    setData(null);
                }
            })
            .finally(() => isMounted && setLoading(false));

        return () => (isMounted = false);
    }, [url, options]);
    
    return {
        loading,
        data,
        error
    };
};
