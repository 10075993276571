import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../context/AppContext';

export const OnSale = () => {

    const { ventas:{ventasData,ventasLoading,ventasError} } = useContext(AppContext);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <main className="main">
            <section className="obras section">
                <h2 className="section_titulo textCenter" data-aos="zoom-in" data-aos-duration="1000">Propiedades en venta</h2>
                <div className="cardsText grid">
                    {
                        ventasLoading ?
                        (
                            <h1>Loading...</h1>
                        )   :
                        ventasError ?
                        (
                            <h1>{ ventasError }</h1>
                        )   :
                        (
                            ventasData && 
                            (
                                ventasData.map( ( d, i ) => (
                                    (
                                        <div className="tranYlow" key={ i }>
                                            <div className="cardsPropiedades"  data-aos="zoom-in" data-aos-duration="1500">
                                                <div className="imagenGaleriaProd">
                                                    <Link to={`/ventas/${d.id}`}>
                                                        <img src={ d.imagen } alt={d.nombre} />
                                                        <div className="hoverGaleria">
                                                            <p>{ d.nombre }</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    )    
                                ))
                            )
                        )
                    }
                </div>
            </section>
        </main>
    )
}