import React, { useEffect } from 'react';

import ca1 from '../../assets/img/bx_bxs-map.svg';
import ca2 from '../../assets/img/mdi_bed.svg';
import ca3 from '../../assets/img/fa-solid_shower.png';
import ca4 from '../../assets/img/fa-solid_car.png';
import ca5 from '../../assets/img/clarity_compass-solid.svg';
import ca6 from '../../assets/img/noun_water.svg';
import ca7 from '../../assets/img/noun_Stairs.svg';
import ca8 from '../../assets/img/Group.svg';

import { Slider } from '../details/slider/Slider';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

export const FullDetail = ({ props }) => {

    const { 
        nombre, slider, caracteristicas, acercaDeLaPropiedad, plantaBaja,
        plantaBajaImagen, plantaAlta, plantaAltaImagen, youtubeVideoId,
        parrafosFinales, ubicacion } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            {
                props ?
                (
                    <main className="main">
                        <section className="obrasVenta section">
                            <h2 className="section_titulo textCenter" data-aos="zoom-in" data-aos-duration="1000">{nombre}</h2>
                            <div className="slider" data-aos="zoom-in" data-aos-duration="1000">
                                <div className="imgContenedor">
                                    <div className="center">
                                        {
                                            slider.length >= 1 ?
                                            (
                                                <Slider imgs={slider} />
                                            )   :
                                            (
                                                <h2>No hay imagenes cargadas</h2>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </section>
                        {
                            youtubeVideoId && (
                                <section className='youtubeEmbedVideo' data-aos="zoom-in" data-aos-duration="1500">
                                    <h4 className="wrapper_video_title textCenter">Video</h4>
                                    <LiteYouTubeEmbed
                                        id={youtubeVideoId} // Default none, id of the video or playlist
                                        adNetwork={true} // Default true, to preconnect or not to doubleclick addresses called by YouTube iframe (the adnetwork from Google)
                                        poster="hqdefault" // Defines the image size to call on first render as poster image. Possible values are "default","mqdefault",  "hqdefault", "sddefault" and "maxresdefault". Default value for this prop is "hqdefault". Please be aware that "sddefault" and "maxresdefault", high resolution images are not always avaialble for every video. See: https://stackoverflow.com/questions/2068344/how-do-i-get-a-youtube-video-thumbnail-from-the-youtube-api
                                        title={`YouTube Embed video de ${nombre || 'G+M'}`} // a11y, always provide a title for iFrames: https://dequeuniversity.com/tips/provide-iframe-titles Help the web be accessible ;)
                                        noCookie={true} //Default false, connect to YouTube via the Privacy-Enhanced Mode using https://www.youtube-nocookie.com
                                    /> 
                                </section>
                            )
                        }
                        <section className="obrasVenta section">
                            <div className="gridObras">
                                <div className="wrapper_left" data-aos="zoom-in" data-aos-duration="1500">
                                    <h4 className="wrapper_left_title textCenter margin">Características</h4>
                                    <div className="rowObras">
                                        <ul>
                                            {
                                                <>
                                                    <li className="listaCaracteristicas"><img
                                                            className="svgObras" src={ ca1 }
                                                            width={30} height={30}
                                                            alt={nombre} />{ caracteristicas.ubicacion ? caracteristicas.ubicacion : "Sin datos" }
                                                    </li>
                                                    <li className="listaCaracteristicas"><img
                                                            className="svgObras" src={ ca2 }
                                                            width={30} height={30} alt={nombre} 
                                                            />{ caracteristicas.dormitorios ? caracteristicas.dormitorios : "Sin datos" }
                                                    </li>
                                                    <li className="listaCaracteristicas"><img
                                                            className="svgObras" src={ ca3 }
                                                            width={30} height={30} alt={nombre} />
                                                        { caracteristicas.baños ? caracteristicas.baños : "Sin datos" }
                                                    </li>
                                                    <li className="listaCaracteristicas"><img
                                                            className="svgObras" src={ ca4 }
                                                            width={30} height={30} alt={nombre} />
                                                        { caracteristicas.cochera ? caracteristicas.cochera : "Sin datos" }
                                                    </li>
                                                </>
                                            }
                                        </ul>
                                        <ul>
                                            <li className="listaCaracteristicas"><img className="svgObras"
                                                src={ ca5 } width={30} height={30} alt={nombre}
                                                />{ caracteristicas.orientacion ? caracteristicas.orientacion : "Sin datos" }
                                            </li>
                                            <li className="listaCaracteristicas"><img className="svgObras toilet"
                                                src={ ca6 } width={30} height={30} alt={nombre}
                                                />{ caracteristicas.toilette ? caracteristicas.toilette : "Sin datos" }
                                            </li>
                                            <li className="listaCaracteristicas"><img className="svgObras"
                                                src={ ca7 } width={30} height={30} alt={nombre} 
                                                />{ caracteristicas.pisos ? caracteristicas.pisos : "Sin datos" }
                                            </li>
                                            <li className="listaCaracteristicas"><img className="svgObras" src={ ca8 }
                                                width={30} height={30} alt={nombre}
                                                />{ caracteristicas.estado ? caracteristicas.estado : "Sin datos" }
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="wrapper_left" data-aos="zoom-in" data-aos-duration="1500">
                                    <h4 className="wrapper_left_title textCenter margin">
                                        Acerca de la propiedad
                                    </h4>
                                    {
                                        acercaDeLaPropiedad.length >= 1 ?
                                        (
                                            acercaDeLaPropiedad.map( ( p, i ) => (
                                                <p className="wrapper_left_text" key={i}>
                                                    {p.parrafo}
                                                </p>
                                            ))
                                        )   :
                                        (
                                            <h2>Sin datos</h2>
                                        )
                                    }
                                </div>
                            </div>
                        </section>
                        <section className="obrasVenta section" >
                            <div className="grid">
                                <div className="wrapper_left" data-aos="zoom-in" data-aos-duration="1500">
                                    <h4 className="wrapper_left_title_obras">Planta baja</h4>
                                    <ul>
                                        {
                                            plantaBaja.length >= 1 ?
                                            (
                                                plantaBaja.map( ( p, i ) => (
                                                    <li className="listaObras" key={i}>
                                                        {
                                                            p.item.length >= 1 ?
                                                            (
                                                                p.item
                                                            )   :
                                                            (
                                                                'Sin datos'
                                                            )
                                                        }
                                                    </li>
                                                ))
                                            )   :
                                            (
                                                <h2>Sin datos</h2>
                                            )
                                        }
                                    </ul>
                                </div>
                                <div className="wrapper_right">
                                    <img src={ plantaBajaImagen } alt={nombre} data-aos="zoom-in" data-aos-duration="1500" />
                                </div>
                            </div>
                        </section>
                        <section className="obrasVenta section">
                            <div className="grid">
                                <div className="wrapper_left" data-aos="zoom-in" data-aos-duration="1500">
                                    <h4 className="wrapper_left_title_obras">Planta alta</h4>
                                    <ul>
                                        {
                                            plantaAlta.length >= 1 ?
                                            (
                                                plantaAlta.map( ( p, i ) => (
                                                    <li className="listaObras" key={i}>
                                                        {
                                                            p.item.length >= 1 ?
                                                            (
                                                                p.item
                                                            )   :
                                                            (
                                                                'Sin datos'
                                                            )
                                                        }
                                                    </li>
                                                ))
                                            )   :
                                            (
                                                <h2>Sin datos</h2>
                                            )
                                        }
                                    </ul>
                                </div>
                                <div className="wrapper_right">
                                    <img src={ plantaAltaImagen } alt={nombre} data-aos="zoom-in" data-aos-duration="1500"/>
                                </div>
                            </div>
                        </section>
                        
                        <section className="obrasVenta" >
                            {
                                parrafosFinales.length >= 1 ?
                                (
                                    parrafosFinales.map( ( p, i ) => (
                                        <p className="wrapper_left_text" key={i} data-aos="zoom-in" data-aos-duration="1500">
                                            {
                                                p.parrafo.length >= 1 ?
                                                (
                                                    p.parrafo
                                            )   :
                                                (
                                                    'Sin datos'
                                                )
                                            }
                                        </p>
                                    ))
                                )   :
                                (
                                    <h2>Sin datos</h2>
                                )
                            }
                        </section>
                        {
                            ubicacion && (
                                <section className="obrasVenta" data-aos="zoom-in" data-aos-duration="1500">
                                    <h2 className="section_titulo textCenter">Ubicación</h2>
                                    <div className="imgContenedor">
                                        <div className="center">
                                            <img src={ ubicacion } alt={nombre} />
                                        </div>
                                    </div>
                                </section>
                            )
                        }
                    </main>
                ) :
                (
                    <h1>Cargando...</h1>
                )
            }
        </>
    )
}