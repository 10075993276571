import React from 'react'

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import SwiperCore, {Pagination,Navigation,Autoplay} from 'swiper/core';
SwiperCore.use([Pagination,Navigation,Autoplay]);

export const Slider = ({imgs}) => {
    return (

        <>
            <Swiper spaceBetween={30} centeredSlides={true} autoplay={{ "delay": 2500, "disableOnInteraction": false }}
                pagination={{ "clickable": true }} navigation={true} className="">
                    {
                        imgs.map( (img, i) => (
                            <SwiperSlide key={i}><img src={img.imagen} alt={ i } /></SwiperSlide>
                        ))
                    }
            </Swiper>
        </>
    )
}
