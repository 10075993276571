import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import SwiperCore, {Pagination,Navigation,Autoplay} from 'swiper/core';
// import arias from '../../assets/comentarios/arias.jpg'
import chivilcoy from '../../assets/comentarios/chivilcoy.jpg'
import jardin from '../../assets/comentarios/jardin.jpg'
import lagunaAzul from '../../assets/comentarios/lagunaAzul.jpg'
import republica from '../../assets/comentarios/republica.jpg'
import viamonte from '../../assets/comentarios/viamonte.jpg'
// import useWindowDimensions from '../../hooks/useWindowDimensions';

SwiperCore.use([Pagination,Navigation,Autoplay]);



export const TestimonialsSlider = () => {

    // const { width } = useWindowDimensions();

    return (
        <Swiper spaceBetween={30} centeredSlides={true} autoplay={{ "delay": 10000, "disableOnInteraction": false }}
            navigation={true} className="mySwiper">
            {/* {
                width > 1150 &&
                (
                    <>
                        <SwiperSlide>
                            <div className="grid">
                                <div className="cardTestimonios" data-aos="zoom-in" data-aos-duration="1000">
                                    <div>
                                        <div className="testimoniosCard_img">
                                            <img src={ jardin } alt="casa de campo" />
                                        </div>
                                        <p className="cardText">
                                            Conocimos a los arquitectos Carmen y Mariano para convertir nuestra casa familiar en un
                                            jardín de infantes. Luego de charlas, ideas y propuestas comenzaron a darle forma a este
                                            nuevo desafío. Como resultado de todo su trabajo profesional quedó un edificio
                                            totalmente renovado, colorido, amplio y con diseño innovador pero lo que mejor supieron
                                            interpretar y materializar fue la calidez, la Alegría y el amor de la que fuera nuestra
                                            casa familiar. Hoy es el espacio donde aprenden jugando nuestros niños llenando de vida
                                            cada rincón. Gracias totales por su trabajo pero por sobre todo les agradecemos por la
                                            responsabilidad y compromiso que tuvieron y tienen siempre!!
                                        </p>
                                    </div>
                                    <div>
                                        <p className="cardSubText">Testimonio jardin de infantes</p>
                                    </div>
                                </div>
                                <div className="cardTestimonios" data-aos="zoom-in" data-aos-duration="1000">
                                    <div>
                                        <div className="testimoniosCard_img">
                                            <img src={ viamonte } alt="casa de campo" />
                                        </div>
                                        <p className="cardText">
                                            Contratamos al estudio g+m para reformar nuestro departamento. Queriamos un living
                                            amplio y
                                            modernizarlo por completo. Nos aportaron ideas muy creativas y cumplieron con todo lo
                                            que se
                                            habia acordado. Muchas gracias!! Quedo hermoso y lo super disfrutamos
                                        </p>
                                    </div>
                                    <div>
                                        <p className="cardSubText">Dpto. Viamonte</p>
                                    </div>
                                </div>
                                <div className="cardTestimonios" data-aos="zoom-in" data-aos-duration="1000">
                                    <div>
                                        <div className="testimoniosCard_img">
                                            <img src={ arias } alt="casa de campo" />
                                        </div>
                                        <p className="cardText">
                                            Tenemos un local y atras habia una casa que estaba totalmente abandonada. Usabamos parte
                                            como
                                            deposito y el resto estaba cerrada. Nada ventilaba y pensabamos que era insalvable. Los
                                            arquitectos nos asesoraron y presentaron propuestas para reformarla y hacer un
                                            departamento. La
                                            idea inicial era alquilarlo pero quedo tan hermoso que nos terminamos yendo a vivir.
                                            Felices con
                                            nuestra nueva casa!
                                        </p>
                                    </div>
                                    <div>
                                        <p className="cardSubText">Casa Arias</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="grid">
                                <div className="cardTestimonios" data-aos="zoom-in" data-aos-duration="1000">
                                    <div>
                                        <div className="testimoniosCard_img">
                                            <img src={ chivilcoy } alt="casa de campo" />
                                        </div>
                                        <p className="cardText">
                                            Contactamos a arquitectos gm para la reforma de nuestra terraza. Teniamos un espacio muy grande y desaprovechado y  queriamos un lugar cálido y verde con espacio cubierto y descubierto para compartir con familia y amigos. 
                                            No solo supieron interpretar lo que queríamos si no que superaron nuestras expectativas generando un espacio soñado. g
                                            Gracias arquitectos gm!
                                        </p>
                                    </div>
                                    <div>
                                        <p className="cardSubText">Testimonio chivilcoy</p>
                                    </div>
                                </div>
                                <div className="cardTestimonios" data-aos="zoom-in" data-aos-duration="1000">
                                    <div>
                                        <div className="testimoniosCard_img">
                                            <img src={ lagunaAzul } alt="casa de campo" />
                                        </div>
                                        <p className="cardText">
                                            Conocimos a Mariano y Carmen a través de un familiar que nos recomendó su trabajo. Desde el primer encuentro supieron entender qué necesitábamos y nos ofrecieron rápidamente algunas alternativas diferentes para concretar nuestro proyecto.
                                            Enseguida pusieron manos a la obra. Nos asesoraron y acompañaron en cada decisión durante las distintas etapas y necesidades.
                                            Su servicio se destaca por la calidez, la amabilidad en su atención y el profesionalismo puesto en cada paso de la construcción.
                                            Disponen de un enorme caudal de conocimientos en todas las instancias de planificación, gestión y concreción de lo proyectado. 
                                            Les estaremos siempre agradecidos por acompañarnos en esta etapa!!!
                                        </p>
                                    </div>
                                    <div>
                                        <p className="cardSubText">Testimonio laguna azul</p>
                                    </div>
                                </div>
                                <div className="cardTestimonios" data-aos="zoom-in" data-aos-duration="1000">
                                    <div>
                                        <div className="testimoniosCard_img">
                                            <img src={ republica } alt="casa de campo" />
                                        </div>
                                        <p className="cardText">
                                            Muy conforme con el trabajo realizado. Desde la primer visita supieron escuchar y plasmar en la obra cada requerimiento. Atentos a las necesidades e inquietudes y siempre bien predispuestos. Excelente grupo de trabajo de principio a fin, grandes profesionales. Recomiendo 100%
                                        </p>
                                    </div>
                                    <div>
                                        <p className="cardSubText">Testimonio obra republica</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </>
                )
            } */}
            {
                
                (
                    <>
                        <SwiperSlide>
                            <div className="grid">
                                <div className="cardTestimonios" data-aos="zoom-in" data-aos-duration="1000">
                                    <div>
                                        <div className="testimoniosCard_img">
                                            <img src={ jardin } alt="casa de campo" />
                                        </div>
                                        <p className="cardText">
                                            Conocimos a los arquitectos Carmen y Mariano para convertir nuestra casa familiar en un
                                            jardín de infantes. Luego de charlas, ideas y propuestas comenzaron a darle forma a este
                                            nuevo desafío. Como resultado de todo su trabajo profesional quedó un edificio
                                            totalmente renovado, colorido, amplio y con diseño innovador pero lo que mejor supieron
                                            interpretar y materializar fue la calidez, la Alegría y el amor de la que fuera nuestra
                                            casa familiar. Hoy es el espacio donde aprenden jugando nuestros niños llenando de vida
                                            cada rincón. Gracias totales por su trabajo pero por sobre todo les agradecemos por la
                                            responsabilidad y compromiso que tuvieron y tienen siempre!!
                                        </p>
                                    </div>
                                    <div>
                                        <p className="cardSubText">Jardin de infantes</p>
                                    </div>
                                </div>
                                <div className="cardTestimonios" data-aos="zoom-in" data-aos-duration="1000">
                                    <div>
                                        <div className="testimoniosCard_img">
                                            <img src={ viamonte } alt="casa de campo" />
                                        </div>
                                        <p className="cardText">
                                            Contratamos al estudio g+m para reformar nuestro departamento. Queriamos un living
                                            amplio y
                                            modernizarlo por completo. Nos aportaron ideas muy creativas y cumplieron con todo lo
                                            que se
                                            habia acordado. Muchas gracias!! Quedo hermoso y lo super disfrutamos
                                        </p>
                                    </div>
                                    <div>
                                        <p className="cardSubText">Dpto. azcuenaga</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="grid">
                                <div className="cardTestimonios" data-aos="zoom-in" data-aos-duration="1000">
                                    <div>
                                        <div className="testimoniosCard_img">
                                            <img src="https://res.cloudinary.com/dvnlvmoiu/image/upload/v1636157412/Reformas/14_Ph%20Cabrero/ph_cabrero_2_17_vzhlqz.jpg" alt="casa de campo" />
                                        </div>
                                        <p className="cardText">
                                            Tenemos un local y atras habia una casa que estaba totalmente abandonada. Usabamos parte
                                            como
                                            deposito y el resto estaba cerrada. Nada ventilaba y pensabamos que era insalvable. Los
                                            arquitectos nos asesoraron y presentaron propuestas para reformarla y hacer un
                                            departamento. La
                                            idea inicial era alquilarlo pero quedo tan hermoso que nos terminamos yendo a vivir.
                                            Felices con
                                            nuestra nueva casa!
                                        </p>
                                    </div>
                                    <div>
                                        <p className="cardSubText">Ph Cabrero</p>
                                    </div>
                                </div>
                                <div className="cardTestimonios" data-aos="zoom-in" data-aos-duration="1000">
                                    <div>
                                        <div className="testimoniosCard_img">
                                            <img src={ chivilcoy } alt="casa de campo" />
                                        </div>
                                        <p className="cardText">
                                            Contactamos a arquitectos gm para la reforma de nuestra terraza. Teniamos un espacio muy grande y desaprovechado y  queriamos un lugar cálido y verde con espacio cubierto y descubierto para compartir con familia y amigos. 
                                            No solo supieron interpretar lo que queríamos si no que superaron nuestras expectativas generando un espacio soñado. g
                                            Gracias arquitectos gm!
                                        </p>
                                    </div>
                                    <div>
                                        <p className="cardSubText">Chivilcoy</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="grid">
                                <div className="cardTestimonios" data-aos="zoom-in" data-aos-duration="1000">
                                    <div>
                                        <div className="testimoniosCard_img">
                                            <img src={ lagunaAzul } alt="casa de campo" />
                                        </div>
                                        <p className="cardText">
                                            Conocimos a Mariano y Carmen a través de un familiar que nos recomendó su trabajo. Desde el primer encuentro supieron entender qué necesitábamos y nos ofrecieron rápidamente algunas alternativas diferentes para concretar nuestro proyecto.
                                            Enseguida pusieron manos a la obra. Nos asesoraron y acompañaron en cada decisión durante las distintas etapas y necesidades.
                                            Su servicio se destaca por la calidez, la amabilidad en su atención y el profesionalismo puesto en cada paso de la construcción.
                                            Disponen de un enorme caudal de conocimientos en todas las instancias de planificación, gestión y concreción de lo proyectado. 
                                            Les estaremos siempre agradecidos por acompañarnos en esta etapa!!!
                                        </p>
                                    </div>
                                    <div>
                                        <p className="cardSubText">Laguna azul</p>
                                    </div>
                                </div>
                                <div className="cardTestimonios" data-aos="zoom-in" data-aos-duration="1000">
                                    <div>
                                        <div className="testimoniosCard_img">
                                            <img src={ republica } alt="casa de campo" />
                                        </div>
                                        <p className="cardText">
                                            Muy conforme con el trabajo realizado. Desde la primer visita supieron escuchar y plasmar en la obra cada requerimiento. Atentos a las necesidades e inquietudes y siempre bien predispuestos. Excelente grupo de trabajo de principio a fin, grandes profesionales. Recomiendo 100%
                                        </p>
                                    </div>
                                    <div>
                                        <p className="cardSubText">Obra republica</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </>
                )
            }
        </Swiper>   
    )
}
