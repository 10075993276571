import React, { useEffect } from 'react'
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import { Slider } from '../details/slider/Slider';

export const Detail = ({ props }) => {

    const { nombre, slider, informacionGeneral, informacionGeneralImagen, youtubeVideoId } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <main className="main">
            <section className="obrasDescripcion section">
                <h2 className="section_titulo textCenter" data-aos="zoom-in" data-aos-duration="1000">{ nombre }</h2>
                <div className="slider" data-aos="zoom-in" data-aos-duration="1500">
                <div className="imgContenedor">
                        <div className="center">
                            {
                                slider.length >= 1 ?
                                (
                                    <Slider imgs={slider} />
                                )   :
                                (
                                    <h2>No hay imagenes cargadas</h2>
                                )
                            }
                        </div>
                    </div>
                </div>
            </section>
            {
                youtubeVideoId && (
                    <section className='youtubeEmbedVideo' data-aos="zoom-in" data-aos-duration="1500">
                        <h4 className="wrapper_video_title textCenter">Video</h4>
                        <LiteYouTubeEmbed
                            id={youtubeVideoId} // Default none, id of the video or playlist
                            adNetwork={true} // Default true, to preconnect or not to doubleclick addresses called by YouTube iframe (the adnetwork from Google)
                            poster="hqdefault" // Defines the image size to call on first render as poster image. Possible values are "default","mqdefault",  "hqdefault", "sddefault" and "maxresdefault". Default value for this prop is "hqdefault". Please be aware that "sddefault" and "maxresdefault", high resolution images are not always avaialble for every video. See: https://stackoverflow.com/questions/2068344/how-do-i-get-a-youtube-video-thumbnail-from-the-youtube-api
                            title={`YouTube Embed video de ${nombre || 'G+M'}`} // a11y, always provide a title for iFrames: https://dequeuniversity.com/tips/provide-iframe-titles Help the web be accessible ;)
                            noCookie={true} //Default false, connect to YouTube via the Privacy-Enhanced Mode using https://www.youtube-nocookie.com
                        /> 
                    </section>
                )
            }
            <section className="obrasDescripcion section">
                <div className="grid">
                    <div className="wrapper_left">
                        <h4 className="wrapper_left_title textCenter" data-aos="zoom-in" data-aos-duration="1000">Información general</h4>
                        {
                            informacionGeneral.length >= 1 ?
                            (
                                informacionGeneral.map( ( p, i ) => (
                                    <p className="wrapper_left_text" key={i} data-aos="zoom-in" data-aos-duration="1500">
                                        {p.parrafo}
                                    </p>
                                ))
                            )   :
                            (
                                <h2>Sin datos</h2>
                            )
                        }
                    </div>
                    <div className="wrapper_right" data-aos="zoom-in" data-aos-duration="1500">
                        <img src={ informacionGeneralImagen } alt={ nombre } />
                    </div>
                </div>
            </section>
     
        </main>
    )
}