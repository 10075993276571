import React, { createContext, useEffect, useState } from 'react';
import { useFetch } from '../hooks/useFetch';

export const AppContext = createContext()

export const AppProvider = ({ children }) => {

    const { data: sliderData, loading: sliderLoading, error: sliderError } = useFetch("https://raw.githubusercontent.com/Mariano-Matarazzo/ArquitectosG-M/main/slider.json");
    const [slider, setSlider] = useState({sliderData,sliderLoading,sliderError});
    useEffect(() => {
        setSlider({sliderData,sliderLoading,sliderError})
    }, [sliderData,sliderLoading,sliderError]);

    const { data: nuevasData, loading: nuevasLoading, error: nuevasError } = useFetch("https://raw.githubusercontent.com/Mariano-Matarazzo/ArquitectosG-M/main/nuevas.json");
    const [nuevas, setNuevas] = useState({nuevasData,nuevasLoading,nuevasError});
    useEffect(() => {
        setNuevas({nuevasData,nuevasLoading,nuevasError})
    }, [nuevasData,nuevasLoading,nuevasError]);

    const { data: ventasData, loading: ventasLoading, error: ventasError } = useFetch("https://raw.githubusercontent.com/Mariano-Matarazzo/ArquitectosG-M/main/venta.json");
    const [ventas, setVentas] = useState({ventasData,ventasLoading,ventasError});
    useEffect(() => {
        setVentas({ventasData,ventasLoading,ventasError})
    }, [ventasData,ventasLoading,ventasError]);

    const { data: reformasData, loading: reformasLoading, error: reformasError } = useFetch("https://raw.githubusercontent.com/Mariano-Matarazzo/ArquitectosG-M/main/reformas.json");
    const [reformas, setReformas] = useState({reformasData,reformasLoading,reformasError});
    useEffect(() => {
        setReformas({reformasData,reformasLoading,reformasError})
    }, [reformasData,reformasLoading,reformasError]);
    
    //valores globales
    const datas = { slider, nuevas, ventas, reformas }
    return (
        <>
            <AppContext.Provider value={datas}>
                {children}
            </AppContext.Provider>
        </>
    )
}