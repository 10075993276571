import React, { useContext } from 'react'
import { useParams } from 'react-router';
import { AppContext } from '../../../context/AppContext';
import { Detail } from '../../pages/Detail';

export const DetailContainer = () => {

    let { id } = useParams();

    const { nuevas:{nuevasData,nuevasLoading,nuevasError} } = useContext(AppContext);

    return (
        <>
            {   
                nuevasLoading ?
                (
                    <h1>Loading...</h1>
                )   :
                nuevasError ?
                (
                    <h1>{ nuevasError }</h1>
                )   :
                (
                    nuevasData && 
                    (
                        nuevasData.map( (d) => (
                            id === d.id && (
                                <Detail key={d.id} props={{...d}} />
                            )    
                        ))
                    )
                )
            }
        </>
    )
}
