import React, { useEffect, useLayoutEffect, useRef } from 'react'
import { Link, NavLink } from 'react-router-dom';
import logo from '../../assets/img/logo.png'
// import { usePathName } from '../../hooks/usePathName';

export const Navbar = ({main}) => {

    const aBrand = useRef();
    const nav = useRef();

    const handleScroll = () => {
        let scrollTop = window.pageXOffset || document.documentElement.scrollTop;

        if (scrollTop > 20) {
            aBrand.current?.classList.add("nav__afterLogo");
            nav.current?.classList.add("navbar--white");
        } else {
            aBrand.current?.classList.remove("nav__afterLogo");
            nav.current?.classList.remove("navbar--white");
        }
    }

    const handleScrollClick = () => {
        window.scrollTo(0, 0);
        navDrop.current.classList.remove('show');
    }

    useLayoutEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.addEventListener("scroll", handleScroll);
        };
    }, [])

    const navDrop = useRef();
    useEffect(() => {
        main.current.addEventListener('click', () => {
            navDrop.current.classList.remove('show');
        });
    }, [main])

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light fixed" ref={ nav }>
            <div className="container-fluid row2">
                <div className="navbar-brand">
                    <Link to="/" onClick={ handleScrollClick }><img src={ logo } alt="GyM" className="nav__logo" ref={ aBrand } /></Link>
                </div>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
                    aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon" />
                </button>
                <div ref={ navDrop } className="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav">
                        <li className="nav-item" >
                            <NavLink exact to="/" className="nav-link" activeClassName="active" onClick={ handleScrollClick }>Inicio</NavLink>
                        </li>
                        <li className="nav-item dropdown">
                            <Link to="/ventas" className="nav-link dropdown-toggle" id="navbarDropdownMenuLink"
                                role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Obras
                            </Link>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                <li>
                                    <NavLink exact to="/ventas" className="dropdown-item" activeClassName="active" onClick={ handleScrollClick }>En venta</NavLink>
                                </li>
                                <li>
                                    <NavLink exact to="/nuevas-ventas" className="dropdown-item" activeClassName="active" onClick={ handleScrollClick }>Nuevas</NavLink>
                                </li>
                                <li>
                                    <NavLink exact to="/reformas" className="dropdown-item" activeClassName="active" onClick={ handleScrollClick }>Reformas</NavLink>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <NavLink exact to="/servicios" className="nav-link" activeClassName="active" onClick={ handleScrollClick }>Servicios</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink exact to="/nosotros" className="nav-link" activeClassName="active" onClick={ handleScrollClick }>Nosotros</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink exact to="/contacto" className="nav-link" activeClassName="active" onClick={ handleScrollClick }>Contacto</NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}