import React, { useState, useRef, useEffect } from 'react'
import { useForm } from '../../hooks/useForm';

export const Contact = () => {

    const sender = useRef();

    const handleInputFocus = ({target}) => target.parentNode.classList.add("focus");

    const handleInputBlur = ({target}) => (target.value === "") && target.parentNode.classList.remove("focus");

    const [ formValues, handleInputChange, reset ] = useForm({
        name: '',
        mail: '',
        tel: '',
        message: ''
    });
    const {name, mail, tel, message} = formValues;
    
    const [err, setErr] = useState({
        errName: '',
        errMail: '',
        errTel: '',
        errMessage: ''
    });
    const { errName, errMail, errTel, errMessage } = err;

    const [loadingAndErr, setLoadingAndErr] = useState({
        loading: false,
        error: false,
        ok: false
    })
    const { loading, error, ok } = loadingAndErr;
    
    const handleError = () => {
        let errors = {};
        if ( name === undefined || name.trim().length <= 2 ) {
            errors.errName = 'El nombre es requerido';
        }
        if ( !mail ) {
            errors.errMail = 'El Email es requerido';
        } else if ( !/\S+@\S+\.\S+/.test(mail) ) {
            errors.errMail = 'La direccion de Email es invalida';
        }
        if ( tel === undefined || tel.trim().length < 8 ) {
            errors.errTel = 'El telefono debe contener al menos 8 numeros';
        }
        if ( message === undefined || !message.trim() ) {
            errors.errMessage = 'El mensaje es requerido';
        } else if ( message.trim().length <= 15 ) {
            errors.errMessage = 'El mensaje debe contener mas de 15 caracteres';
        }

        return setErr(errors)
    }

    const handleForm = (e) => {
        e.preventDefault();
        if (!errName && !errMail && !errTel && !errMessage) {

            setLoadingAndErr({ ...loadingAndErr,loading: true });

            sender.current.disabled = true;
            sender.current.classList.add('disabled--btn');

            fetch("https://formsubmit.co/ajax/cd910b5d5fd7a52d887e5270815f73dc", {
                method: "POST",
                headers: { 
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({...formValues})
            })
            .then(response => response.json())
            .then(data => {
                if (data.success === 'true') {
                    setLoadingAndErr({ ...loadingAndErr,loading: false, ok: 'El mensaje fue enviado con exito' });
                    reset();
                } else {
                    throw new Error(data.message);                
                }
                console.log(data);
            })
            .catch(error => {
                setLoadingAndErr({ ...loadingAndErr,loading: false, error: 'Ocurrio un error, intente nuevamente!' });
                console.warn(error);
            })
            .finally(
                setTimeout(() => {
                    setLoadingAndErr({
                        loading: false,
                        error: false,
                        ok: false
                    });
                    sender.current.disabled = false;
                    sender.current.classList.remove('disabled--btn');
                }, 5000)
            )
        };
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <main className="main">
            <section className="contacto section">
                <h2 className="section_titulo textCenter" data-aos="zoom-in" data-aos-duration="1000">Contactanos</h2>
                <div className="container" data-aos="zoom-in" data-aos-duration="1500">
                    <span className="big-circle" />
                    <div className="form">
                        <div className="contact-info" data-aos="zoom-in" data-aos-duration="1500">
                            <h3 className="title" data-aos="zoom-in" data-aos-duration="2000">Mantengámonos en contacto</h3>
                            <p className="text">
                                Puedes enviarnos un formulario con cualquier consulta y te
                                responderemos lo mas pronto posible!
                            </p>
                            <div className="info">
                                <div className="information tranYlow">
                                    <p><a href="mailto:info@arquitectosgm.com.ar">info@arquitectosgm.com.ar</a></p>
                                </div>
                                <div className="information tranYlow">
                                    <p><a href="https://api.whatsapp.com/send?phone=541166402983&amp;text=Hola!&nbsp;mi&nbsp;nombre&nbsp;es&nbsp;...">+54 9 11 6640-2983</a></p>
                                </div>
                            </div>
                            <div className="social-media">
                                <p>Nuestras redes</p>
                                <div className="social-icons">
                                    <a href="https://www.facebook.com/gm.arquitectos">
                                        <i className="fab fa-facebook-f" />
                                    </a>
                                    <a href="https://www.instagram.com/arquitectos_gm">
                                        <i className="fab fa-instagram" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="contact-form" data-aos="zoom-in" data-aos-duration="1500">
                            <span className="circle one" />
                            <span className="circle two" />
                            <form onSubmit={ handleForm } autoComplete="off">
                                <h3 className="title" data-aos="zoom-in" data-aos-duration="2000">Contactanos</h3>
                                <div className="input-container">
                                    <input type="text" name="name" value={ name } onFocus={ handleInputFocus } onBlur={ handleInputBlur } autoComplete="off"
                                        className={ errName ? "input form-control is-invalid" : errName === '' ? "input" : "input form-control is-valid" }
                                        onChange={ handleInputChange }  />
                                    <label htmlFor="name">Nombre</label>
                                    <span>Nombre</span>
                                </div>
                                <div className="formError">
                                    <span>{ errName }</span> 
                                </div>
                                <div className="input-container">
                                    <input type="email" name="mail" value={ mail } onFocus={ handleInputFocus } onBlur={ handleInputBlur } autoComplete="off"
                                        className={ errMail ? "input form-control is-invalid" : errMail === '' ? "input" : "input form-control is-valid" }
                                        onChange={ handleInputChange }  />
                                    <label htmlFor="email">Email</label>
                                    <span>Email</span>
                                </div>
                                <div className="formError">
                                    <span>{ errMail }</span> 
                                </div>
                                <div className="input-container">
                                    <input type="tel" name="tel" value={ tel } onFocus={ handleInputFocus } onBlur={ handleInputBlur } autoComplete="off"
                                        className={ errTel ? "input form-control is-invalid" : errTel === '' ? "input" : "input form-control is-valid" }
                                        onChange={ handleInputChange }  />
                                    <label htmlFor="phone">Celular</label>
                                    <span>Celular</span>
                                </div>
                                <div className="formError">
                                    <span>{ errTel }</span> 
                                </div>
                                <div className="input-container textarea"> 
                                    <textarea name="message" value={ message } onFocus={ handleInputFocus } onBlur={ handleInputBlur } autoComplete="off"
                                        className={ errMessage ? "input form-control is-invalid" : errMessage === '' ? "input" : "input form-control is-valid" }
                                        onChange={ handleInputChange }   />
                                    <label htmlFor="message">Mensaje</label>
                                    <span>Mensaje</span>
                                </div>
                                <div className="formError">
                                    <span>{ errMessage }</span> 
                                </div>
                                {
                                    ( loading ? 
                                    (
                                        <input className="button contactButton" type="submit" 
                                            onClick={ handleError } value="Enviando..." ref={ sender }/> 
                                    ) :
                                    (
                                        <input className="button contactButton" type="submit" 
                                            onClick={ handleError } value="Enviar Consulta" ref={ sender }/> 
                                    ))
                                }
                                {
                                    error && 
                                    (
                                        <div className="formResult">
                                            <span className="formResultErr">{ error }</span> 
                                        </div>
                                    ) 
                                }
                                {
                                    ok &&
                                    (
                                        <div className="formResult">
                                            <span className="formResultOk">{ ok }</span> 
                                        </div>
                                    )
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mapa" data-aos="zoom-in" data-aos-duration="1500">
                <iframe title="mapa" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3284.1780953277776!2d-58.439082184770506!3d-34.59965778046051!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcca73a20c7eb9%3A0x42505c9fa24435ed!2sAr%C3%A1oz%20406%2C%20C1414DPJ%20CABA!5e0!3m2!1ses-419!2sar!4v1636086299913!5m2!1ses-419!2sar" width="100%" height={500} style={{border: 0}} allowFullScreen loading="lazy" />
            </section>
        </main>
    )
}