import React, { useEffect } from 'react'

export const Services = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <main className="main">
            <section className="servicios section">
                <h2 className="section_titulo textCenter" data-aos="zoom-in" data-aos-duration="1000">Nuestros servicios</h2>
                <div className="cardsText gridServicios">
                    <div className="cardsText_card cardServicios" data-aos="zoom-in" data-aos-duration="1500">
                        <div className="tranY">
                            <h3 className="cardServiciosTitle"><i className="far fa-check-circle" />Diseñamos tu proyecto</h3>
                            <p className="cardServiciosP">Te hacemos un diseño personalizado según tu gusto y necesidades.</p>
                        </div>
                    </div>
                    <div className="cardsText_card cardServicios" data-aos="zoom-in" data-aos-duration="1500">
                        <div className="tranY">
                            <h3 className="cardServiciosTitle"><i className="far fa-check-circle" />Coordinamos tu proyecto</h3>
                            <p className="cardServiciosP">Coordinamos las etapas de diseño, trámites, compra de materiales y
                                construcción.
                            </p>
                        </div>
                    </div>
                    <div className="cardsText_card cardServicios" data-aos="zoom-in" data-aos-duration="1500">
                        <div className="tranY">
                            <h3 className="cardServiciosTitle"><i className="far fa-check-circle" />Gestionamos y tramitamos el
                                plano municipal</h3>
                            <p className="cardServiciosP">Realizamos todos los trámites en el municipio hasta la aprobación de
                                planos.</p>
                        </div>
                    </div>
                    <div className="cardsText_card cardServicios" data-aos="zoom-in" data-aos-duration="1500">
                        <div className="tranY">
                            <h3 className="cardServiciosTitle"><i className="far fa-check-circle" />Realizamos el calculo
                                estructural del proyecto</h3>
                            <p className="cardServiciosP">Nuestro Departamento de Ingeniería estudia y analiza todos los proyectos.
                            </p>
                        </div>
                    </div>
                    <div className="cardsText_card cardServicios" data-aos="zoom-in" data-aos-duration="1500">
                        <div className="tranY">
                            <h3 className="cardServiciosTitle"><i className="far fa-check-circle" />Planificamos tareas de obra</h3>
                            <p className="cardServiciosP">Planificamos y coordinamos el personal de obra en todas las etapas.
                                Contamos con un equipo de trabajo especializado en cada rubro.
                            </p>
                        </div>
                    </div>
                    <div className="cardsText_card cardServicios" data-aos="zoom-in" data-aos-duration="1500">
                        <div className="tranY">
                            <h3 className="cardServiciosTitle"><i className="far fa-check-circle" />Compramos y entregamos los
                                materiales</h3>
                            <p className="cardServiciosP">Realizamos la logística de materiales, desde la compra hasta la entrega en
                                obra.
                            </p>
                        </div>
                    </div>
                    <div className="cardsText_card cardServicios" data-aos="zoom-in" data-aos-duration="1500">
                        <div className="tranY">
                            <h3 className="cardServiciosTitle"><i className="far fa-check-circle" />Dirigimos tu obra</h3>
                            <p className="cardServiciosP">Realizamos la dirección de obra, asegurando que se realice según el
                                proyecto y en los tiempos estipulados.
                                Nos caracterizamos por un seguimiento constante y detallista.
                            </p>
                        </div>
                    </div>
                    <div className="cardsText_card cardServicios" data-aos="zoom-in" data-aos-duration="1500">
                        <div className="tranY">
                            <h3 className="cardServiciosTitle"><i className="far fa-check-circle" />Te asesoramos en la elección de
                                terminaciones</h3>
                            <p className="cardServiciosP">Te guiaremos en el diseño y en la elección de los mejores productos.
                            </p>
                        </div>
                    </div>
                    <div className="cardsText_card lastCard cardServicios" data-aos="zoom-in" data-aos-duration="1500">
                        <div className="tranY">
                            <h3 className="cardServiciosTitle"><i className="far fa-check-circle" />Te ofrecemos garantía por
                                nuestros trabajos</h3>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}